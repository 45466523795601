.account-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    .main-wrapper {
        background: $secondary-800;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .account-content {
            @include margin-padding(null, 20px 0);
            .account-logo {
                margin-bottom: 30px;
                text-align: center;
                img {
                    width: 100px;
                    @include respond-below(custom575) {
                        width: 70px;
                    }
                }
            }
            .account-box {
                background-color: $white;
                border: 1px solid $secondary-100;
                box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
                margin: 0 auto;
                overflow: hidden;
                width: 480px;
                @include rounded(4px);
                @include respond-below(custom991) {
                    width: 100%;
                    @include margin-padding(0 0 20px, null);
                }
                .input-block {
                    margin-bottom: 25px;
                }
                label {
                    color: $secondary;
                    font-size: $font-size-16;
                    font-weight: normal;
                }
                .form-control {
                    background-color: $light-200;
                    border: 1px solid $light-900;
                    height: 46px;
                    @include rounded(4px);
                }
                #toggle-password {
                    cursor: pointer;
                    margin-right: 1px;
                    @include position(absolute,15px,10px,null,null);
                }
                .account-btn {
                    background: $primary;
                    background: linear-gradient(to right, $primary 0%, $pink 100%);
                    border: 0;
                    display: block;
                    font-size: $font-size-22;
                    width: 100%;
                    @include rounded(4px);
                    @include margin-padding(null,  10px 26px);
                }
                .account-wrapper {
                    @include margin-padding(null, 30px);
                    .account-footer {
                        text-align: center;
                    }
                    .account-title {
                        font-size: $font-size-26;
                        font-weight: $font-weight-medium;
                        margin-bottom: 5px;
                        text-align: center;
                    }
                    .account-subtitle {
                        color: $secondary-600;
                        font-size: $font-size-18;
                        text-align: center;
                        @include margin-padding(0 0 30px,null);
                    }
                    .account-box {
                        .input-block {
                            @include margin-padding(0 0 25px,null);
                            label {
                                color: $secondary;
                                font-size: $font-size-16;
                                font-weight: normal;
                                margin-bottom: 5px;
                            }
                            .form-control {
                                background-color: $light-200;
                                border: 1px solid $light-900;
                                height: 46px;
                                @include rounded(4px);
                            }
                        }
                    }
                }
                .otp-wrap {
                    margin-bottom: 30px;
                    text-align: center;
                    .otp-input {
                        background-color: $white;
                        border: 1px solid $light-900;
                        display: inline-block;
                        font-size: $font-size-24;
                        font-weight: $font-weight-medium;
                        height: 70px;
                        line-height: 29px;
                        margin-right: 15px;
                        text-align: center;
                        width: 70px;
                        @include rounded(4px);
                        @include respond-below(custom575) {
                            height: 50px;
                            width: 52px;
                            @include margin-padding(10px, null);
                        }
                    }
                }
                .lock-user {        
                    margin-bottom: 20px;
                    text-align: center;
                    img {
                        margin-bottom: 15px;
                        width: 100px;
                    }
                }
            }
        }
    }
}