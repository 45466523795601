.custom-file-container {
	box-sizing: border-box;
	position: relative;
	display: block;
	label {
		color: $primary;
	}
}
.custom-file-container__custom-file {
	box-sizing: border-box;
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;
	margin-top: 5px;
	&:hover {
		cursor: pointer;
	}
}
.custom-file-container__custom-file__custom-file-input {
	box-sizing: border-box;
	min-width: 14rem;
	max-width: 100%;
	height: calc(2.25rem + 2px);
	margin: 0;
	opacity: 0;
	&:focus {
		& ~ span {
			outline: 1px dotted #dddde0;
			outline: 0px auto -webkit-focus-ring-color;
		}
	}
}
.custom-file-container__custom-file__custom-file-control {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: auto;
	overflow: hidden;
	line-height: 1.5;
	user-select: none;
	background-clip: padding-box;
	border-radius: .25rem;
	height: auto;
	border: 1px solid #f1f2f3;
	color: #3b3f5c;
	font-size: 15px;
	padding: 8px 10px;
	letter-spacing: 1px;
	background-color: #f1f2f3;
}
.custom-file-container__custom-file__custom-file-control__button {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 6;
	display: block;
	height: auto;
	padding: 10px 16px;
	line-height: 1.25;
	background-color: $secondary-300;
	color: $white;
	border-left: 1px solid #e0e6ed;
	box-sizing: border-box;
}
.custom-file-container__image-preview {
	box-sizing: border-box;
	margin-top: 54px;
	margin-bottom: 40px;
	height: 250px;
	width: 100%;
	border-radius: 4px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: $white;
	overflow: auto;
	padding: 15px;
	@include transition(all 0.2s ease);
}
.custom-file-container__image-multi-preview {
	position: relative;
	box-sizing: border-box;
	border-radius: 6px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	float: left;
	margin: 1.858736%;
	width: 29.615861214%;
	height: 90px;
	box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
	@include transition(all 0.2s ease);
}
.custom-file-container__image-multi-preview__single-image-clear {
	background: $white;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	text-align: center;
	margin-top: -6px;
	@include rounded(50%);
	box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
	@include position(absolute,null,null,null,-6px);
	&:hover {
		background: #cbcbbd;
		cursor: pointer;
	}
}
.custom-file-container__image-multi-preview__single-image-clear__icon {
	color: $primary;
	display: block;
	margin-top: -2px;
}
.note-editor {
	&.card {
		padding: 0.5rem;
		margin-bottom: 0;
	}
}
.note-toolbar {
	&.card-header {
		padding: 0;
		padding-bottom: 0.5rem;
	}
}
.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single,
.irs--flat .irs-bar, .irs--flat .irs-handle>i:first-child,
.irs--flat .irs-handle.state_hover>i:first-child, .irs--flat .irs-handle:hover>i:first-child {
	background-color: $primary;
}
.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
	border-top-color: $primary;
}
.note-editor.note-frame {
	.note-statusbar {
		.note-resizebar {
			padding-top: 0;
			height: 0;
			width: 100%;
			cursor: ns-resize;
			background: transparent;
			border: 0;
		}
	}
}
