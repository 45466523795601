/********** Template CSS **********/
:root {
  --primary: #fb5b21;
  --secondary: #bdbdbf;
  --light: #f4f5f8;
  --dark: #222429;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
  margin-left: 0;
}

h1,
h2,
.font-weight-bold {
  font-weight: 700 !important;
}

h3,
h4,
.font-weight-semi-bold {
  font-weight: 600 !important;
}

h5,
h6,
.font-weight-medium {
  font-weight: 500 !important;
}

.btn {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn-primary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 30px;
  height: 30px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 0;
  z-index: 99;
}

.navbar-dark .navbar-nav .nav-link {
  font-family: "Oswald", sans-serif;
  margin-right: 30px;
  padding: 40px 0;
  color: var(--light);
  font-size: 17px;
  text-transform: uppercase;
  outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }
}

.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(34, 36, 41, 0.8);
  z-index: 1;
}

@media (max-width: 576px) {
  .carousel-caption h4 {
    font-size: 18px;
    font-weight: 500 !important;
  }

  .carousel-caption h1 {
    font-size: 30px;
    font-weight: 600 !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.bg-hero {
  background: linear-gradient(rgba(34, 36, 41, 0.8), rgba(34, 36, 41, 0.8)),
    url(../img/carousel-1.jpg) top center no-repeat;
  background-size: cover;
}

.programe::after {
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% - 45px);
  top: 135px;
  left: 0;
  background: var(--dark);
  z-index: -1;
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.2);
}

.team-item .team-overlay {
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 36, 41, 0.9);
  transition: 0.5s;
  opacity: 0;
}

.team-item:hover .team-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
}

.testimonial-carousel .owl-nav {
  margin-top: 30px;
  display: flex;
  justify-content: start;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  font-size: 22px;
  border-radius: 45px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.testimonial-carousel .owl-item img {
  width: 90px;
  height: 90px;
}

.blog-item img {
  transition: 0.5s;
}

.blog-item:hover img {
  transform: scale(1.2);
}

@media (min-width: 991.98px) {
  .credit {
    background: var(--primary);
  }
}
