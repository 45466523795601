.punch-det {
    background-color: $light-200;
    border: 1px solid $light-900;
    @include rounded(4px);
    @include margin-padding(0 0 20px, 10px 15px);
   
    h6 {
        line-height: 20px;
        @include margin-padding(0,null);
    }
    p {
        color: $secondary-700;
        font-size: $font-size-14;
        @include margin-padding(0,null);
    }
}
.punch-info {
    @include margin-padding(0 0 20px,null);
    .punch-hours {
        background-color: $light-200;
        border: 5px solid $light-900;
        font-size: $font-size-18;
        height: 120px;
        width: 120px;
        @include margin-padding(0 auto,null);
        @include rounded(50%);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
    }
}
.punch-btn-section {
    text-align: center;
    @include margin-padding(0 0 20px, null);
    .punch-btn {
        font-size: $font-size-18;
        font-weight: $font-weight-semibold;
        max-width: 100%;
        @include margin-padding(null, 8px 40px);
        @include rounded(50px);
    }
    .stats-box {
        background-color: $light-200;
        border: 1px solid $light-900;
        @include margin-padding(0 0 15px, 5px);
        p {
            font-size: $font-size-12;
            @include margin-padding(0, null);
        }
        h6 {
            @include margin-padding(0, null);
        }
    }
}
.recent-activity {
    .res-activity-list {
        height: 328px;
        list-style-type: none;
        overflow-y: auto;
        position: relative;
        @include margin-padding(0, 0 0 0 30px);
        &:after {
            content: "";
            border: 1px solid $secondary-100;
            @include position(absolute,0,null,0,4px);
        }
        li {
            @include margin-padding(0 0 15px, null);
            position: relative;
            &:before {
                content: "";
                width: 10px;
                height: 10px;
                border: 2px solid $primary;
                z-index: 2;
                background: $white;
                @include rounded(100%);
                @include margin-padding(0 0 0 15px, null);
                @include position(absolute,0,null,null,-45px);
            }
        }
    }
    p {
        font-size: $font-size-13;
        @include margin-padding(0, null);
    }
    .res-activity-time {
        color:$secondary-300;
        font-size: $font-size-12;
    }
}
.att-statistics{
    .progress {
        height: 4px;
    }
    .stats-info {
        background-color: $white;
        border: 1px solid $secondary-100;
        text-align: center;
        @include rounded(4px);
        @include margin-padding(0 0 5px, 15px);
        p {
            font-size: $font-size-12;
            @include margin-padding(0 0 5px, null);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
        }
    }
}