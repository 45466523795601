.card {
    &.analytics-card {
        background: $white;
        padding: 24px;
        border-color: $white;
        box-shadow: 0px 4px 24px 0px #BCBCBC40;
        .card-header {
            padding: 0;
            background: $white;
            border: 0;
            margin-bottom: 24px;
            @include respond-below(custom575) {
                flex-direction: column;
                align-items: flex-start !important;
            }
            h3 {
                font-size: $font-size-20;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                a {
                    color: $title-color;
                    margin-left: 5px;
                }
            }
            .select2-container--default .select2-selection--single .select2-selection__rendered {
                font-size: $font-size-14;
            }
            .select2-results__option {
                font-size: $font-size-14;
            }
        }
        .card-body {
            padding: 0;
            .table {
                th {
                    padding: 10px;
                    background: $light-200;
                    color: $title-color;
                    &:first-child {
                        border-left: 1px solid $secondary-100;
                    }
                    &:last-child {
                        border-right: 1px solid $secondary-100;
                    }
                }
                td {
                    &:first-child {
                        border-left: 1px solid $secondary-100;
                    }
                    &:last-child {
                        border-right: 1px solid $secondary-100;
                    }
                }
            }
        }
    }
}
.activities-list {
    ul {
        li {
            background: $light-300;
            padding: 15px 10px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .activity-name {
                h5 {
                    font-size: $font-size-14;
                    color: $secondary-900;
                    margin-bottom: 3px;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
                p {
                    font-size: $font-size-14;
                    color: $sub-title;
                    margin-bottom: 10px;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
            }
            .user-activity {
                display: flex;
                align-items: center;
                @include respond-below(custom767) {
                    margin: 20px 0;
                }
               
                span {
                    flex-shrink: 0;
                    display: inline-flex;
                    margin-right: 5px;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                    }
                }
                h6 {
                    margin-bottom: 0;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
            }
        }
    }
}
.card-select {
    ul {
        @include respond-below(custom575) {
            flex-direction: column;
            align-items: flex-start !important;
        }
        li {
            + li {
                @include respond-below(custom575) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}