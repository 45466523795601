.tooltip {
  @mixin tooltip-color($color) {
    .tooltip-inner {
      background-color: $color;
    }
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
    &.bs-tooltip-top .tooltip-arrow::before {
      border-top-color: $color;
    }
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before {
      border-right-color: $color;
    }
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
    &.bs-tooltip-start .tooltip-arrow::before {
      border-left-color: $color;
    }
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before {
      border-bottom-color: $color;
    }
  }
  &.tooltip-primary {
    @include tooltip-color($primary);
  }
  &.tooltip-secondary {
    @include tooltip-color($secondary);
  }
  &.tooltip-warning {
    @include tooltip-color($warning);
  }
  &.tooltip-info {
    @include tooltip-color($info);
  }
  &.tooltip-success {
    @include tooltip-color($success);
  }
  &.tooltip-danger {
    @include tooltip-color($danger);
  }
  &.tooltip-light {
    @include tooltip-color($light);
    .tooltip-inner {
      color: $secondary-400;
    }
  }
}
.btn-list button {
  margin: 0 0.375rem 0.375rem 0;
}
.btn-list {
  margin-block-end: -0.5rem;
  .btn-warning {
    color: $white;
  }
}
