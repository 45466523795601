.topnav-dropdown-header{
    border-bottom: 1px solid $light-600;
    text-align: center;
    font-size: $font-size-12;
    height: 32px;
    line-height: 32px;
    @include margin-padding(null, 0 12px);
    .notification-title {
        color: $secondary-900;
        display: block;
        float: left;
        font-size: $font-size-14;
    }
    .clear-noti {
        color: $danger;
        float: right;
        font-size: $font-size-11;
        text-transform: uppercase;
    }
}
.notifications {
    .noti-content {
        height: 290px;
        width: 350px;
        overflow-y: auto;
        position: relative;
    }
    .notification-list {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            margin-top: 0;
            border-bottom: 1px solid $light-300;
            a {
                display: block;
                @include margin-padding(null, 12px);
                @include rounded(2px);
            }
            .noti-title {
                color: $secondary-900;
            }
            .noti-details {
                color: $secondary-500;
                @include margin-padding(0, null);
            }
            p.noti-time{
                @include margin-padding(0, null);
            } 
            .notification-time {
                font-size: $font-size-12;
                line-height: 1.35;
                color: $secondary-300;
            }
            .list-item {
                border: 0;
                padding: 0;
                position: relative;
                .list-left {
                    height: 48px;
                    position: absolute;
                    width: 48px;
                }
                .list-body {
                    @include margin-padding(null, 0 0 0 50px);
                    .message-author {
                        color: $secondary-900;
                        float: left;
                        font-weight: $font-weight-medium;
                        width: 175px;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .message-time {
                        color: $secondary-600;
                        float: right;
                        font-size: $font-size-11;
                    }
                    .message-content {
                        color: $secondary-900;
                        font-size: $font-size-13;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.topnav-dropdown-footer {
    border-top: 1px solid $light-600;
    font-size: $font-size-12;
    height: 32px;
    line-height: 32px;
    @include margin-padding(null, 0 12px);
    a {
        display: block;
        text-align: center;
        color: $secondary-900;
    }
}
.list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}
.list-body {
    @include margin-padding(null, 0 0 0 50px);
    .message-author {
        color: $secondary-900;
        float: left;
        font-weight: $font-weight-medium;
        width: 175px;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .message-time {
        color: $secondary-600;
        float: right;
        font-size: $font-size-11;
    }
    .message-content {
        color: $secondary-900;
        font-size: $font-size-13;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}